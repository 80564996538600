import Swiper, { Navigation } from 'swiper';
Swiper.use([Navigation]);

const worksSwiper = new Swiper('.js-worksSlider', {
  loop: true,
  slidesPerView: 1,
  spaceBetween: 28,
  navigation: {
    nextEl: '.js-worksNext',
    prevEl: '.js-worksPrev',
  },
  breakpoints: {
    768: {
      slidesPerView: 3,
      spaceBetween: 30,
    },
  },
});
